//ToDo
// 1. Refactor the code below to use styled-components
// 2. Use the styled-components in the Modal component
import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  lazy,
  Suspense,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Modal from "react-modal";
import moment from "moment";
import "moment/locale/es";
import "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { fetchEvents, selectEvent } from "../../actions/planner";
import { startLoading, endLoading } from "../../actions/ui";
import { showError } from "../../helpers/messages";
import { useTranslation } from "react-i18next";
import CustomToolbar from "./components/CustomToolbar";
import CalendarSearchForm from "./CalendarSearchForm";
import PageTitle from "../../components/UI/PageTitle";
import PageContainer from "../../components/UI/PageContainer";
import CustomAgenda from "./components/CustomAgenda";
import ContentContainer from "../../components/UI/ContentContainer";
import { darken } from "polished";
import { set } from "lodash";
import LoadingScreen from "../../hooks/common/loadingScreen";

const localizer = momentLocalizer(moment);
const EventDetailsModal = lazy(() => import("./components/EventDetailsModal"));

const CalendarTrainerScreen = () => {
  const { eventsList } = useSelector((state) => state.planner);
  const [events, setEvents] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { eventFilter } = useSelector((state) => state.planner);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { isModalOpen, toggleModal } = useModal();
  const calendarRef = useRef(null);
  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const STATUS_MAP = {
    1: "Planificado",
    2: "Borrador",
    3: "Suspendido",
    4: "Cancelado",
    5: "Finalizado",
  };

  const setDateFromFirstEvent = (events) => {
    if (events.length > 0) {
      const firstValidEvent = events.find(event => [1, 2, 3].includes(event.status));
      if (firstValidEvent) {
        const firstEventDate = firstValidEvent.start;
        setDate(firstEventDate);
      }
    }
  };

  const fetchData = useCallback(
    async (options) => {
      setLoading(true);
      dispatch(startLoading());
      const res = await dispatch(fetchEvents(options));
      dispatch(endLoading());
      setLoading(false);
      if (!res.ok) {
        showError(t("message-title-error"), t(res.message));
      }
    },
    [dispatch, t]
  );

  useEffect(() => {
    fetchData({ ...eventFilter });
  }, [dispatch, eventFilter, fetchData]);

  useEffect(() => {
    dispatch(selectEvent(null));
  }, [dispatch]);

  useEffect(() => {
    const mappedEvents = eventsList.map((event) => {
      let title = '';
      let centroCoste = '';
      let tipoFormacion = '';
      let direccion = '';

      if (event.curso.gep_tipo_formacion === "GEP SERVICES") {

        tipoFormacion = "preventivo";
        title = `Preventivo ( ${event.curso.p_tipo_servicio} ) - ${event.curso.customer_name_pipedrive}`;
        direccion = event.direccion_preventivo;

      } else if (event.curso.gep_tipo_formacion === "FORMACIóN ABIERTA") {

        tipoFormacion = "fa";
        title = `FA - ${event.curso.titulo}`;
        centroCoste = `FA - ${event.curso.p_centro_de_coste}`;
        direccion = event.curso.p_direccion || "Sin Asignar";

      } else if (event.sala.centro.centro && event.curso.gep_tipo_formacion === "FORMACIóN EMPRESAS") {

        tipoFormacion = "fe";
        title = `${event.sala.centro.centro} - ${event.curso.titulo}`;
        direccion = event.curso.p_direccion || event.curso.p_sede_formacion.toUpperCase();

      } else {

        tipoFormacion = 'default';
        title = event.curso.titulo;
        direccion = event.p_direccion || "Sin Asignar";

      }

      return {
        ref_int: event.num_sesion,
        id: event.id,
        title: title,
        tipoFormacion: tipoFormacion,
        centroCoste: event.curso.p_centro_de_coste,
        dealId: event.curso.deal_id_pipedrive,
        start: moment(event.f_inicio).toDate(),
        f_inicio: moment(event.f_inicio).toDate(),
        end: moment(event.f_fin).toDate(),
        center: event.sala.centro.centro,
        room: event.sala.nombre,
        trainingType: event.curso.gep_tipo_formacion,
        client: event.curso.customer_name_pipedrive,
        trainer1: event.trainer && event.trainer.label ? event.trainer.label : "Sin asignar",
        trainer2: event.trainer_suplente && event.trainer_suplente.label ? event.trainer_suplente.label : "Sin asignar",
        trainerId1: event.trainer && event.trainer.id ? event.trainer.id : "Sin asignar",
        trainerId2: event.trainer_suplente && event.trainer_suplente.id ? event.trainer_suplente.id : "Sin asignar",
        p_caes: event.curso.p_caes,
        p_caes_check: event.curso.caes_validado,
        p_fundae: event.curso.p_fundae,
        p_fundae_check: event.curso.fundae_validado,
        p_hotel_pernocta: event.curso.p_hotel_pernocta,
        p_hotel_pernocta_check: event.curso.pernocta_validado,
        observaciones: event.observaciones != null ? event.observaciones : [],
        plazas: event.curso.plazas,
        p_owner_name: event.curso.p_owner_name,
        status: event.estados,
        address: direccion,
        p_tipo_servicio: event && event.curso ? event.curso.p_tipo_servicio : undefined,
        companies: Array.isArray(event?.curso?.cursos) ? event.curso.cursos : [],
        studentsTotal: event.curso.alumnos_inscriptos_formacion,
        courseId: event.curso_id,
        turno: event.turno,
      };
    });
    setEvents(mappedEvents);
    setDateFromFirstEvent(mappedEvents);
  }, [eventsList]);

  const handleEventClick = (event) => {
    setDate(event.start);
    setSelectedEvent(event);
    toggleModal();
  };

  function useModal() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    async function toggleModal() {
      // if (isModalOpen) {
      //   await fetchData({ ...eventFilter });
      // }
      setIsModalOpen(!isModalOpen);
    }

    return {
      isModalOpen,
      toggleModal,
    };
  }

  const getEventStyle = (status) => {
    const baseStyle = {
      backgroundColor: "lightblue",
      color: "black",
      BorderColor: "white",
      borderWidth: "1px",
      borderRadius: "5px",
    };

    const statusColorMap = {
      Planificado: "#c8e6c9",
      Borrador: "#D1D3D1",
      Suspendido: "#FEF962",
      Cancelado: "#FE7E6F",
      Finalizado: "#6F9FFE",
    };

    return {
      ...baseStyle,
      backgroundColor: statusColorMap[status] || baseStyle.backgroundColor,
      borderColor: baseStyle.borderColor,
      // Add hover effect
      "&:hover": {
        backgroundColor: darken(
          0.1,
          statusColorMap[status] || baseStyle.backgroundColor
        ),
      },
    };
  };
  const reloadCalendarAndUpdateEvent = async (eventId) => {
    const currentDate = date;

    await fetchData({ ...eventFilter });
    setDate(currentDate);

  };

  return (
    <PageContainer>
      <PageTitle title={t("screens-planner-list-calendar-title")}></PageTitle>
      {!isModalOpen && <CalendarSearchForm />}
      <div>
        {loading && <LoadingScreen />}
        <Calendar
          ref={calendarRef}          
          tooltipAccessor={(e) => {
            if (e.tipoFormacion === 'fa') {
              return `${e.title} | ${e.centroCoste} | ${e.trainer1} | ${e.trainer2}`;
            }

            if (e.tipoFormacion === 'fe') {
              return `${e.title} | ${e.centroCoste} | ${e.client} | ${e.trainer1} | ${e.trainer2}`;
            }

            if (e.tipoFormacion === 'preventivo') {
              return `${e.title} | ${e.client} | ${e.trainer1} | ${e.trainer2}`;
            }

            return e.title;
          }}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          style={{ height: "100vh", width: "100%" }}
          components={{
            toolbar: CustomToolbar,
            agenda: {
              event: (props) => (
                <CustomAgenda
                  {...props}
                  color={STATUS_MAP[props.event.status]}
                />
              ),
            },
          }}
          onSelectEvent={handleEventClick}
          defaultView="agenda"
          timeslots={2} // establishes 30-minute intervals
          date={date}
          onNavigate={(newDate) => {
            setDate(newDate);
          }}
          eventPropGetter={(event, start, end, isSelected) => {
            const newStyle = getEventStyle(STATUS_MAP[event.status]);
            return {
              className: "",
              style: newStyle,
            };
          }}
        />

        <Suspense fallback={<div>Loading...</div>}>
          <EventDetailsModal
            selectedEvent={selectedEvent}
            isOpen={isModalOpen}
            onRequestClose={() => {
              toggleModal();
            }}
            onEventUpdateSuccess={reloadCalendarAndUpdateEvent}
          />
        </Suspense>
      </div>
    </PageContainer>
  );
};

export default CalendarTrainerScreen;